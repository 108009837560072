<template lang="pug">
.v-lst-list
  .v-lst-list-item(v-for="list in shuffledNoteSummaries" :key="list.id")
    a.external_link#component_top_list(:href="`/list/${list.id}`")
      picture
        source(type="image/webp" :srcset="`${convertImage(list.thumbnail.thumb_1000.url, 217, 307, 'webp')}, ${convertImage(list.thumbnail.thumb_1000.url, 434, 614, 'webp')} 2x`")
        source(:srcset="`${convertImage(list.thumbnail.thumb_1000.url, 217, 307, 'jpg')}, ${convertImage(list.thumbnail.thumb_1000.url, 434, 614, 'jpg')} 2x`")
        img(:src="convertImage(list.thumbnail.thumb_1000.url, 217, 307, 'jpg')" width="217" height="307" loading="lazy")

</template>
<script>
import { convertImage } from '~/libs/imagepix'

export default {
  props: {
    noteSummaries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      shuffle_lists: []
    }
  },
  computed: {
    shuffledNoteSummaries() {
      return this.shuffle(this.noteSummaries)
    }
  },
  methods: {
    convertImage,
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1))
        const tmp = array[i]
        array[i] = array[r]
        array[r] = tmp
      }
      return array.slice(0, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/individual/list/list.scss';
.v-lst-list {
  flex-shrink: 0;
  display: flex;
  flex-wrap: unset;
  justify-content: unset;
  @include media(pc) {
    width: 100%;
  }
  &-item {
    flex-shrink: 0;
    @include media(pc) {
      width: calc((100% - #{$spacer_edge} * 4) / 5);
    }
    @include media(sp) {
      width: calc((100vw - #{$spacer_edge} * 2 - #{$spacer_1}) / 2);
      max-width: 200px;
    }
    a {
      img {
        transition: all 0.2s;
      }
      @include media(pc) {
        &:hover {
          img {
            filter: brightness(70%);
          }
        }
      }
    }
  }
  .v-lst-list-item ~ .v-lst-list-item {
    @include media(pc) {
      margin-left: $spacer_3;
    }
    @include media(sp) {
      margin-left: $spacer_1;
    }
  }
}
</style>
