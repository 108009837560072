<template lang="pug">
//- MEMO:
  - vgt関連の機能
  - 学割設定
  については、現在使用しておらずデザインの確認ができないため削除。
  使用を再開するタイミングで追加してください。
div
  .v-lst-event-thumb
    internal-link(:path="event.detail_url")
      picture
        source(:srcset="`${setImage(event.cover_image.ogp_1200.url, 480, 480, 'webp')}, ${setImage(event.cover_image.ogp_1200.url, 960, 960, 'webp')} 2x`" type="image/webp")
        source(:srcset="`${setImage(event.cover_image.ogp_1200.url, 480, 480, 'jpeg')}, ${setImage(event.cover_image.ogp_1200.url, 960, 960, 'jpeg')} 2x`")
        img(:src="setImage(event.cover_image.ogp_1200.url, 480, 480, 'jpeg')" width="600" height="315" :alt="event.name" loading="lazy")
      .premium(v-if="event.premium_only")
  .v-lst-event-txt
    client-only
      .ribbon(v-if="event.entryable")
        | 参加者受付中
      .ribbon.is-closed(v-else)
        | 受付は終了しました
    .v-lst-event-txt-ttl
      internal-link(:path="event.detail_url")
        | {{ event.name }}
    .v-lst-event-txt-pr(v-if="event.paid_publicity")
      | Sponsored by {{ event.paid_publicity }}
    ul.v-lst-event-txt-info
      li
        icon-calendar-svg
        | {{ removeYear(event.period) }}
      li
        icon-place-svg
        | {{ event.place }}
      li
        icon-pay-svg
        client-only
          //- Premium限定イベント
          template(v-if="event.premium_only")
            .price(v-html="priceTxt(event.premium_entry_fee)")
            | （Premium会員限定）
          //- キャリア限定イベント
          template(v-else-if="event.event_type === 'career'")
            .price(v-html="priceTxt(event.entry_fee)")
            | （Vookキャリアユーザー限定）
          //- 通常イベント
          template(v-else)
            //- 参加費がみんな一緒
            template(v-if="event.entry_fee === event.premium_entry_fee")
              .price(v-html="priceTxt(event.entry_fee)")
            //- 参加費が異なる
            .v-lst-event-txt-info-price(v-else)
              strong(v-if="hasDiscount(event)")
                | 通常価格
              .price(v-html="priceTxt(event.entry_fee)")
              template(v-if="event.premium_entry_fee != null")
                strong Premium会員
                .price(v-html="priceTxt(event.premium_entry_fee)")
</template>
<script>
import RemainingLabel from '~/components/events/RemainingLabel'
import InternalLink from '~/components/InternalLink'
import IconCalendarSvg from '~/assets/images/svg/icon_calendar.svg?inline'
import IconPlaceSvg from '~/assets/images/svg/icon_place.svg?inline'
import IconPeopleSvg from '~/assets/images/svg/icon_people.svg?inline'
import IconPaySvg from '~/assets/images/svg/icon_pay.svg?inline'
import { convertImage } from '~/libs/imagepix'

export default {
  components: {
    RemainingLabel,
    InternalLink,
    IconCalendarSvg,
    IconPlaceSvg,
    IconPeopleSvg,
    IconPaySvg
  },
  props: {
    event: {
      type: Object,
      default: () => []
    }
  },
  computed: {
    priceTxt() {
      return (fee) => {
        if (fee) {
          return `${fee.toLocaleString()}円`
        } else {
          return '無料'
        }
      }
    },
    removeYear() {
      return (date) => {
        const year = /\b\d{4}年/g
        return date.replace(year, '')
      }
    }
  },
  methods: {
    hasDiscount(event) {
      return event.premium_entry_fee != null || event.student_entry_fee != null
    },
    setImage(path, width, height, format) {
      return convertImage(path, width, height, format)
    }
  }
}
</script>
