<template lang="pug">
modal(:name="name" title="会員登録 / ログイン")
  .v-modal-sign_in
    h4
      | {{ description }}には
      br.sp
      | ログインが必要です
    ul.v-sns
      li.v-sns-twitter
        a(@click.prevent="login('twitter')" href="#")
          img(v-lazy="require('~/assets/images/sns_twitter.svg')")
          span X(Twitter)でログイン
      li
        a(@click.prevent="login('facebook')" href="#")
          img(v-lazy="require('~/assets/images/sns_facebook.svg')")
          span Facebookでログイン
      li.v-sns-google
        a(@click.prevent="login('google_oauth2')" href="#")
          img(v-lazy="require('~/assets/images/sns_google.svg')")
          span Googleでログイン
    blockquote
      | Instagramの仕様の変更により「Instagramでログイン」機能が2020年6月29日に終了しました。ログインができなくなってしまった方は、恐れ入りますが、
      nuxt-link(to="/inquiry/new")
        | お問い合わせ
      | よりご連絡ください。
    ul.v-modal-sign_in-mail
      li.v-modal-sign_in-mail-sign_up
        a(href="/users/sign_up" @click.prevent="saveCurrentURLAndGoToPage")
          icon-mail-svg
          | メールアドレスで新規登録
      li.v-modal-sign_in-mail-login
        a(href="/users/sign_in" @click.prevent="saveCurrentURLAndGoToPage") ログイン
    p
      | 登録には
      a(href='/pages/terms' target='_blank') 利用規約
      | に同意する必要があります。
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '~/components/Modal'
import IconMailSvg from '~/assets/images/svg/icon_mail.svg?inline'

export default {
  components: {
    Modal,
    IconMailSvg
  },
  props: {
    description: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('oauth', [
      'loginWithTwitter',
      'loginWithFacebook',
      'loginWithGoogle',
      'loginWithInstagram'
    ]),
    login(provider) {
      this.saveAfterLoginURL()
      switch (provider) {
        case 'twitter':
          this.loginWithTwitter()
          break
        case 'facebook':
          this.loginWithFacebook()
          break
        case 'google_oauth2':
          this.loginWithGoogle()
          break
        case 'instagram':
          this.loginWithInstagram()
          break
        default:
          alert(`Invalid provider: ${provider}`)
      }
    },
    saveCurrentURLAndGoToPage(e) {
      this.saveAfterLoginURL()
      this.$router.push(e.target.pathname)
    },
    saveAfterLoginURL() {
      this.$cookies.set('afterLoginURL', location.pathname)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-modal {
  &-sign_in {
    width: 550px;
    @include media(sp) {
      width: 100%;
    }
    h4 {
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 20px;
      @include media(sp) {
        font-size: 15px;
        padding-bottom: 15px;
      }
    }
    .v-sns {
      margin-bottom: 10px;
    }
    &-sns,
    &-mail {
      padding-bottom: 20px;
      @include media(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      a {
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
      }
      @include media(sp) {
        li + li {
          margin-top: 10px;
        }
      }
    }
    &-mail {
      margin-bottom: 20px;
      border-bottom: 1px solid #dadada;
      li {
        @include media(pc) {
          width: 48%;
        }
      }
      a {
        text-decoration: none;
        padding: 5px 0;
        display: block;
        border-radius: $radius;
        line-height: 29px;
        border-radius: 100px;
      }
      &-sign_up a {
        background: $violet;
        color: #fff;
      }
      &-login a {
        border: 1px solid $violet;
        color: $violet;
      }
      svg {
        width: 29px;
        height: 29px;
        fill: none;
        stroke: #fff;
        vertical-align: top;
        margin: 0 5px 0 -3px;
      }
    }
    p {
      font-size: 90%;
      text-align: center;
      color: $font_color_light;
      a {
        text-emphasis: underline;
      }
    }
    blockquote {
      margin-bottom: 20px;
      text-align: left;
      line-height: 1.5em;
      font-size: 0.9em;
      padding-bottom: 15px;
      border-bottom: solid 1px #dddddd;
      color: $font_color_light;
      @include media(sp) {
        font-size: 0.8em;
      }
      a {
        color: $font_color_light;
      }
    }
  }
}
</style>
