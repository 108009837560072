<template lang="pug">
component(
  :is="isInternalLink(path) ? 'nuxt-link' : 'a'"
  :to="isInternalLink(path) ? absolutePath(path) : ''"
  :href="path"
  :target="isInternalLink(path) ? '' : '_blank'"
  :class="className"
  :event="hooperActive ? '' : 'click'"
  @click="hooperSlide"
)
  slot
</template>

<script>
import { isInternalLink } from '~/libs/common'

export default {
  props: {
    path: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    // hooper drag時のクリック無効にする
    hooperActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isInternalLink,
    absolutePath(path) {
      return path.replace(process.env.BASE_URL, '')
    },
    // hooper drag時のクリック無効にする
    hooperSlide(event) {
      if (this.hooperActive) {
        event.preventDefault()
      }
    }
  }
}
</script>
