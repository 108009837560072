import dayjs from './dayjs'

export function getUserPath(user: User | NoteUser) {
  return `/${user.username || user.id}`
}

export function getUserProfileImage50Url(user: User | NoteUser) {
  return (
    user.profile_image.thumb_50.url ||
    (require('~/assets/images/user-50.png') as string)
  )
}

export function isOrganizedUser(user: CurrentUser) {
  return ['organized', 'organized_school'].includes(user.status)
}
