import Autolinker from 'autolinker'

// pathが内部（リンク）パスであれば true を返す
export function isInternalLink(path) {
  if (!/^https?:\/\//.test(path)) {
    return true
  }
  if (path.indexOf(process.env.BASE_URL) === 0) {
    return true
  }
  return false
}

export function nl2br(str) {
  if (str) {
    return str.replace(/\r?\n/g, '<br>')
  } else {
    return ''
  }
}

// Railsの simple_format
export function simpleFormat(str) {
  if (!str) {
    return str
  }
  str = str.replace(/\r?\n/g, '\n')
  str = str.trim()
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, '</p><p>')
    str = str.replace(/\n/g, '<br>')
    str = `<p>${str}</p>`
    str = Autolinker.link(str)
  }
  return str
}

export function videoProvider(url) {
  if (url.length === 0) {
    return null
  }
  if (/(www\.youtube\.com\/watch\?v=|youtu\.be\/)/.test(url)) {
    return 'youtube'
  } else if (/vimeo\.com\//.test(url)) {
    return 'vimeo'
  }
}

export function videoIframeUrl(url, provider) {
  if (url.length === 0) {
    return null
  }
  switch (provider) {
    case 'youtube':
      return `https://www.youtube.com/embed/${url.match(/[-\w]{11}/)}`
    case 'vimeo':
      return `https://player.vimeo.com/video/${url.match(/[0-9]+/)}`
  }
}
