<template lang="pug">
div
  .all_banner(v-if="showBanner && noCookie()")
    .all_banner-inner
      .all_banner-close(@click="closeBanner()")
      a.external_link(href="https://www.adobe.com/jp/products/premiere.html?sdid=Z662FRRY&mv=display" id="all_banner_adobe_discount" target="_blank")
        img.pc(v-lazy="require('~/assets/images/banner/adobe_discount-pc.png')" width="750")
        img.sp(v-lazy="require('~/assets/images/banner/adobe_discount-sp.png')" width="350")

  .youtube_live#youtube_live(v-if="showLive && noCookie()")
    .youtube_live-inner
      .youtube_live-close(@click="closeLive()")
      .youtube_live-video
        .sp
          .live(v-if="startLive") ● LIVE NOW
        .video
          iframe(loading="lazy" :src="`https://www.youtube.com/embed/${youtube}?autoplay=1&mute=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
      .youtube_live-ttl

        .youtube_live-ttl-session
          .time
            template(v-if="startLive")
              span.pc
                .live ● LIVE NOW
              | 13:00 - 15:00
            template(v-else)
              | 本日13時から生配信！
          .ttl
            span Xperia U25コンペティション
            br.pc
            span 授賞式
          .sp
            a.youtube-link(:href="`https://youtu.be/${youtube}`" target="_blank") Youtubeでみる

      .youtube_live-txt
        p
          | 15作品からグランプリを決定！
          br
          | ノミネート作品を視聴しながら、審査員のSHOTROKさん、Y2さんから講評をいただきます。
          br
          | また、お二人によるセミナー
          strong 「TikTokやInstagramでの集客方法とそれを生かしたマーケティング手法について」
          | も開催します。
        .youtube_live-txt-btn
          a.youtube-link(:href="`https://youtu.be/${youtube}`" target="_blank") Youtubeでみる
</template>

<script>
import { beforeIt } from '~/libs/dayjs'
export default {
  data() {
    return {
      showLive: false,
      startLive: false,
      youtube: 'tusWiBpdkBA',
      showBanner: false
    }
  },
  mounted() {
    const now = new Date()

    // 動画バナー表示期間
    this.showLive =
      !beforeIt('2023-10-07 11:59:59') && beforeIt('2023-10-07 14:59:59')

    // 放送開始（文言：放送中に切り分かる）
    this.startLive = !beforeIt('2023-10-07 13:00:00')

    // // 表示期間 11/16 0:00 ～ 11/30 23:59
    // // バナー画像表示開始
    // const BannerShow = new Date('2020/11/16 00:00:00')

    // // バナー画像表示終了
    // const BannerEnd = new Date('2020/11/27 23:00:00')

    // if (now > BannerShow && now < BannerEnd) {
    //   this.showBanner = true
    // }
  },
  methods: {
    closeLive() {
      this.showLive = false
      this.$cookies.set('floatingBannerClose', true, {
        // maxAge: 60 * 60 * 24 * 3
        maxAge: 60 * 60 // 1時間再表示させない
      })
    },
    closeBanner() {
      this.showBanner = false
      this.$cookies.set('floatingBannerClose', true, {
        maxAge: 60 * 60 * 24 * 3
      })
    },
    noCookie() {
      return !this.$cookies.get('floatingBannerClose')
    }
  }
}
</script>

<style lang="scss">
.youtube_live {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #11093b;
    border-bottom: none;
    // background: #fff url(~assets/images/p/xperia-u25/2023/mv-bottom-bg.png)
    //   no-repeat center / cover;
    color: #11093b;
    @include boxshadow($size: 15px);
    @include media(pc) {
      width: $min_width;
      margin: 0 auto;
      padding: 10px;
    }
    @include media(sp) {
      padding: 5px;
    }
  }
  &-video {
    line-height: 0;
    @include media(pc) {
      width: 240px;
    }
    @include media(sp) {
      width: 40%;
    }
    .video {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      overflow: hidden;
      border: 1px solid rgba(#fff, 0.6);
      @include boxshadow(15px);
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .live {
    background: #fff;
    color: #cb134f;
    font-weight: bold;
    @include media(pc) {
      display: inline-block;
      margin-right: 10px;
      padding: 5px 8px;
      font-size: 15px;
    }
    @include media(sp) {
      padding: 4px 5px;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      font-size: 13px;
    }
  }
  &-close {
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    color: #11093b;
    border: 1px solid #11093b;
    @include boxshadow();
    @include media(sp) {
      top: 3px;
      right: 3px;
      line-height: 33px;
      width: 33px;
      height: 33px;
    }
    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 9%;
      margin: -4% 0 0 -34%;
      background: #11093b;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
  &-ttl {
    @include media(pc) {
      padding: 20px;
    }
    @include media(sp) {
      padding: 10px 10px 10px 15px;
    }
    &-session {
      @include media(pc) {
        white-space: nowrap;
      }
      .time {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 17px;
        @include media(sp) {
          margin-bottom: 3px;
          font-size: 13px;
        }
      }
      .ttl {
        font-size: 22px;
        font-weight: bold;
        line-height: 1.7;
        @include media(sp) {
          font-size: 15px;
          line-height: 1.3;
        }
        span {
          font-weight: bold;
          @include media(pc) {
            display: inline-block;
            padding: 4px;
            line-height: 1;
            background: #a5e7ff;
          }
        }
      }
    }
  }
  &-txt {
    background: #fff;
    @include media(pc) {
      padding: 15px;
      flex: 1;
    }
    @include media(sp) {
      display: none;
    }
    p {
      margin-bottom: 10px;
      line-height: 1.4;
      font-size: 14px;
      strong {
        font-weight: bold;
      }
    }
  }
  .youtube-link {
    display: inline-block;
    border-radius: 100px;
    background: $youtube;
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    @include media(pc) {
      padding: 10px 15px;
      font-size: 14px;
      &:hover {
        filter: brightness(70%);
      }
    }
    @include media(sp) {
      margin-top: 5px;
      padding: 5px 10px;
      font-size: 13px;
    }
  }
}
.all_banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  line-height: 0;
  &-inner {
    position: relative;
    display: table;
    margin: 0 auto;
    @include boxshadow($size: 15px);
  }
  &-close {
    position: absolute;
    top: -12px;
    right: -12px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    color: #555;
    border: 1px solid #11093b;
    @include boxshadow();
    @include media(sp) {
      top: 2px;
      right: 2px;
      line-height: 33px;
      width: 33px;
      height: 33px;
    }
    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 9%;
      margin: -4% 0 0 -34%;
      background: #11093b;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}
</style>
