





























import {
  defineComponent,
  PropType,
  getCurrentInstance,
  reactive,
  nextTick,
  computed
} from '@nuxtjs/composition-api'
// @ts-ignore
import SignInModal from '~/components/SignInModal'
import * as notes from '~/libs/notes'
import * as users from '~/libs/users'
// @ts-ignore
import IconHeartSvg from '~/assets/images/svg/icon_heart.svg?inline'
// @ts-ignore
import IconClipSvg from '~/assets/images/svg/icon_clip.svg?inline'

export default defineComponent({
  components: {
    SignInModal,
    IconHeartSvg,
    IconClipSvg
  },
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true
    }
  },
  setup(props) {
    const { $auth, $axios, $modal } = getCurrentInstance()!.proxy

    const notePath = computed(() => notes.getNotePath(props.note))
    const userPath = computed(() => users.getUserPath(props.note.user))
    const userImageUrl = computed(() =>
      users.getUserProfileImage50Url(props.note.user)
    )
    const noteDate = computed(() => notes.getNoteDateText(props.note))

    const state = reactive({
      liked: props.note.liked || false,
      likesCount: props.note.likes_count,
      clipped: props.note.clipped || false,
      clipsCount: props.note.clips_count,
      modalName: '',
      modalDescription: ''
    })

    const toggleLike = () => {
      if ($auth.loggedIn) {
        if (state.liked) {
          notes.turnOffNoteLike($axios, props.note).then(() => {
            state.liked = false
            state.likesCount--
          })
        } else {
          notes.turnOnNoteLike($axios, props.note).then(() => {
            state.liked = true
            state.likesCount++
          })
        }
      } else {
        state.modalName = 'like'
        state.modalDescription = '記事を「いいね」する'
        nextTick(() => $modal.show(state.modalName))
      }
    }

    const toggleClip = () => {
      if ($auth.loggedIn) {
        if (state.clipped) {
          notes.turnOffNoteClip($axios, props.note).then(() => {
            state.clipped = false
            state.clipsCount--
          })
        } else {
          notes.turnOnNoteClip($axios, props.note).then(() => {
            state.clipped = true
            state.clipsCount++
          })
        }
      } else {
        state.modalName = 'clip'
        state.modalDescription = '記事を「クリップ」する'
        nextTick(() => $modal.show(state.modalName))
      }
    }

    return {
      notePath,
      userPath,
      userImageUrl,
      noteDate,
      state,
      toggleLike,
      toggleClip
    }
  }
})
