<template lang="pug">
client-only
  v-modal(:name="name" height="auto" :click-to-close="closable" @before-open="beforeOpen" :class="'v-modal-wrap-'+name")
    div(v-if="isMounted")
      button.v-modal-close(v-if="closable" @click.prevent="$modal.hide(name)") 閉じる
      template(v-if="title")
        .v-modal-ttl {{ title }}
      .v-modal-content
        slot
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    beforeOpen: {
      type: Function,
      required: false,
      default() {
        return () => {}
      }
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  mounted() {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
.v--modal-overlay {
  background-color: rgba(43, 46, 56, 0.9) !important;
  z-index: 10001 !important; // .l-headerより上
  height: 100% !important;
}
// 下記、リサイズ時も含めて、モーダルを中央に表示するために設定
// （プラグインのresizable機能は、全モーダルの横幅を統一しないとスマホ時にデザインが崩れるため不採用）
.v--modal-background-click {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  overflow-y: scroll;
  &::after {
    display: inline-block;
    height: 100%;
    content: '';
  }
}
.v--modal-box {
  display: inline-block;
  top: unset !important;
  left: unset !important;
  width: unset !important;
  @include media(sp) {
    min-width: 90%;
  }
}
.v--modal-background-click::after,
.v--modal-box {
  vertical-align: middle;
}
.v--modal {
  position: relative;
  border-radius: $radius;
  width: auto;
  max-width: 100%;
  overflow: visible !important;
}
.v-modal {
  &-close {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    border: none;
    font-size: 18px;
    text-align: center;
    background: #bbb;
    cursor: pointer;
    border-radius: 0 $radius 0 0;
    line-height: 999px;
    overflow: hidden;
    @include media(sp) {
      height: 45px;
      width: 45px;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 30px;
      background: #fff;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    & + .v-modal-ttl {
      @include media(pc) {
        padding-right: 60px;
        padding-left: 60px;
      }
      @include media(sp) {
        padding-right: 55px;
      }
    }
  }
  &-ttl {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    height: 50px;
    padding: 10px 15px;
    background: #ddd;
    color: $dark_violet;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: $radius $radius 0 0;
    @include media(sp) {
      font-size: 14px;
      height: 45px;
      padding: 10px;
    }
  }
  &-content {
    padding: 35px;
    @include media(sp) {
      padding: 20px;
    }
  }
}
</style>
