import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { getDateString } from './dayjs'

type SortColumn =
  | 'thirty_days_clips_count'
  | 'published_at'
  | 'last_clipped_at'
  | 'clips_count'

export function searchNotes(
  $axios: NuxtAxiosInstance,
  query: string,
  tags: string[],
  options: {
    page?: number
    per?: number
    sort?: SortColumn
  }
) {
  const params = {
    query,
    tags,
    ...{
      page: 1,
      per: 15,
      ...options
    }
  }

  return $axios.get<Note[]>('/v2/search/notes', { params })
}

export function searchTagNoteCounts(
  $axios: NuxtAxiosInstance,
  query: string,
  tags: string[]
) {
  const params = {
    query,
    tags
  }

  return $axios.$get('/v2/search/count_by_tag_of_notes', { params })
}

export function searchClippingNotes(
  $axios: NuxtAxiosInstance,
  query: string,
  options: {
    page?: number
    per?: number
    sort?: SortColumn
  }
) {
  const params = {
    query,
    ...{
      page: 1,
      per: 15,
      ...options
    }
  }

  return $axios.get<Note[]>('/v2/notes/clipping', { params })
}

export function getNotePath(note: Note) {
  return `/n/${note.id}`
}

export function getEditNotePath(note: Note) {
  return `${getNotePath(note)}/edit`
}

export function getNoteDateText(note: Note) {
  const { published_at: publishedAt, updated_at: updatedAt } = note

  if (publishedAt) {
    if (publishedAt < updatedAt) {
      return getDateString(updatedAt) + ' 更新'
    } else {
      return getDateString(publishedAt)
    }
  } else {
    return getDateString(updatedAt)
  }
}

export function getNoteThumbnailUrl(note: Note) {
  const originalUrl = note.top_image_url.url

  if (originalUrl) {
    if (originalUrl.endsWith('.gif')) {
      return originalUrl
    } else {
      return note.top_image_url.thumb_600.url
    }
  } else {
    return null
  }
}

export function turnOnNoteClip($axios: NuxtAxiosInstance, note: Note) {
  return $axios.post<void>(`/notes/${note.id}/clip_on`)
}

export function turnOffNoteClip($axios: NuxtAxiosInstance, note: Note) {
  return $axios.delete<void>(`/notes/${note.id}/clip_off`)
}

export function turnOnNoteLike($axios: NuxtAxiosInstance, note: Note) {
  return $axios.post<void>(`/notes/${note.id}/like_on`)
}

export function turnOffNoteLike($axios: NuxtAxiosInstance, note: Note) {
  return $axios.delete<void>(`/notes/${note.id}/like_off`)
}
