export const truncate = (line, length, omissions = '...') => {
  if (!line) {
    return ''
  }
  return line.length <= length ? line : `${line.substr(0, length)}${omissions}`
}

export const sanitizeHtml = (html) => {
  return html
    .replace(/<script.*?>.*?<\/script>/gi, '') // scriptタグは中身含め削除
    .replace(/<.+?>/g, '') // その他のタグは中身を残してタグのみ削除
}

export const extractTextFromHtml = (html) => {
  if (!html) {
    return ''
  }
  return html.replace(/<.+?>/gi, ' ')
}

export const range = (start, end) => {
  const shouldReverse = start > end
  const first = shouldReverse ? end : start
  const last = shouldReverse ? start : end
  const array = [...Array(last - first + 1)].map((_, i) => first + i)
  return shouldReverse ? array.reverse() : array
}

// nuxtjsのthis.$route.queryで配列を保証する
export const convQuery = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value
    } else {
      return [value]
    }
  } else {
    return []
  }
}

export const setNpsCookieMaxAge = () => {
  // NPSの回答無しCookie保存期間は一週間
  document.cookie = 'no-answer-nps=true; max-age=604800'
}

// KARTEに返すメール配信状態を真偽値に変更
export const hasMailStatus = (mailState) => {
  if (mailState === 'grant') {
    return true
  } else {
    return false
  }
}

// 生年月日から年齢を取得
export const getAge = (birthDate, nowDate) => {
  if (!birthDate) {
    return null
  }
  birthDate = new Date(birthDate)
  const age = nowDate.getFullYear() - birthDate.getFullYear()
  const thisYearsBirthday = new Date(
    nowDate.getFullYear(),
    birthDate.getMonth(),
    birthDate.getDate()
  )
  return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0)
}

// IDから職業名を取得
export const getOccupationName = (occupationId, occupationOther) => {
  let _occupation = null
  if (occupationId) {
    switch (occupationId) {
      case 1:
        _occupation = '会社員'
        break
      case 2:
        _occupation = 'フリーランス'
        break
      case 3:
        _occupation = '学生'
        break
      case 99:
        _occupation = occupationOther
        break
    }
  }
  return _occupation
}

// 〇時間〇分〇秒という表記から何秒かを算出
export const calculateSecondsFromTime = (time) => {
  let seconds = 0

  const hoursMatched = time.match(/(\d+)時間/)
  if (hoursMatched) {
    seconds += parseInt(hoursMatched[1]) * 3600
  }

  const minutesMatched = time.match(/(\d+)分/)
  if (minutesMatched) {
    seconds += parseInt(minutesMatched[1]) * 60
  }

  const secondsMatched = time.match(/(\d+)秒/)
  if (secondsMatched) {
    seconds += parseInt(secondsMatched[1])
  }

  return seconds
}

// 〇時間〇分〇秒という表記から〇:〇:〇という表記に変換
export const convertTime = (time) => {
  const params = []

  const hoursMatched = time.match(/(\d+)時間/)
  if (hoursMatched) {
    params.push(hoursMatched[1])
  }

  const minutesMatched = time.match(/(\d+)分/)
  if (minutesMatched) {
    params.push(minutesMatched[1])
  }

  const secondsMatched = time.match(/(\d+)秒/)
  if (secondsMatched) {
    params.push(
      // 常に2桁表示にする
      secondsMatched[1].length === 1
        ? `0${secondsMatched[1]}`
        : secondsMatched[1]
    )
  }

  return params.join(':')
}
