<template lang="pug">
  .event-people-status.is-end(v-if="status === 'is-end'")
    i
      | ✕
    | 受付終了
  .event-people-status.is-full(v-else-if="status === 'is-full'")
    i
      | ✕
    | 満席
  .event-people-status.is-small(v-else-if="status === 'is-small'")
    i
      | △
    | {{ `残り${maxPeopleCount - entriedPeopleCount}名` }}
  .event-people-status.is-available(v-else)
    i
      | ◯
    | 残席あり
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    },
    maxPeopleCount: {
      type: Number,
      required: true
    },
    entriedPeopleCount: {
      type: Number,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
//イベント定員ステータス
.event-people-status {
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  line-height: 24px;
  padding: 0 8px 0 32px;
  border-radius: 100px;
  border: 1px solid #eee;
  position: relative;
  overflow: hidden;
  i {
    font-style: normal;
    font-weight: bold;
    width: 24px;
    padding-left: 2px;
    line-height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    color: #fff;
  }
  &.is-available {
    color: $green;
    border: 1px solid $green;
    i {
      background: $green;
    }
  }
  &.is-small {
    color: $yellow;
    border: 1px solid $yellow;
    i {
      background: $yellow;
    }
  }
  &.is-full {
    color: $red;
    border: 1px solid $red;
    i {
      background: $red;
    }
  }
  &.is-end {
    color: #999;
    border: 1px solid #999;
    i {
      background: #999;
    }
  }
}
</style>
