<template lang="pug">
.pr-tag(v-if="isShowAlways || imageURL")
  a(:href="url" target="_blank" @click="onClick")
    template(v-if="imageURL")
      picture
        source(:srcset="convertImage(imageURL.replace('w600_', ''), width * 2, height * 2, 'webp')" type="image/webp")
        img(:src="convertImage(imageURL.replace('w600_', ''), width * 2, height * 2, 'jpeg')" :width="width" :height="height" @load="onLoad")
    template(v-else)
      img(:width="width" :height="height")
</template>

<script>
import { convertImage } from '~/libs/imagepix'

export default {
  props: {
    isShowAlways: {
      // CLS改善用
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      required: true
    },
    scale: {
      type: Number,
      default: 1.0
    },
    loading: {
      type: String,
      default: 'lazy'
    }
  },
  data() {
    return {
      name: null,
      width: 600 * this.scale,
      height: 500 * this.scale,
      adID: null,
      imageURL: null,
      url: null
    }
  },
  mounted() {
    this.$axios.$get(`/pr/${this.code}`).then((adFrame) => {
      this.name = adFrame.name
      this.width = adFrame.width * this.scale
      this.height = adFrame.height * this.scale

      if (adFrame.current_ad) {
        this.adID = adFrame.current_ad.id
        this.imageURL = adFrame.current_ad.image.url
        this.url = adFrame.current_ad.url
      } else if (adFrame.kind === 'banner' && adFrame.default_ad_image) {
        this.imageURL = adFrame.default_ad_image.url
        this.url = adFrame.default_ad_url
      }
    })
  },
  methods: {
    convertImage,
    onLoad() {
      if (this.adID) {
        this.$gtm.push({
          event: 'ad_impression',
          event_category: this.name,
          event_label: `${this.adID}`
        })
        this.$gtm.push({
          event: 'ad_ga4_impression',
          event_category: this.name,
          event_label: `${this.adID}`
        })
      }
    },
    onClick() {
      if (this.adID) {
        this.$gtm.push({
          event: 'ad_click',
          event_category: this.name,
          event_label: `${this.adID}`
        })
        this.$gtm.push({
          event: 'ad_ga4_click',
          event_category: this.name,
          event_label: `${this.adID}`
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pr-tag {
  a {
    position: relative;
    display: inline-block;
    line-height: 0;
    border: 1px solid #dadada;
    &::before {
      content: 'PR';
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 3px 5px;
      line-height: 1;
      background: rgba(#aaa, 0.65);
      color: #fff;
      font-size: 10px;
    }
    img {
      position: relative;
      z-index: 1;
      display: block;
      width: 300px !important;
    }
  }
}
</style>
